import React from "react";
import NormalButton from "../Button";

const SignOutConfirmModal = (props) => {
  const { handleClose, handleLogout } = props;

  return (
    <div className="text-center p-3">
      <p className="text-black fs-24 fw-700">
        Are you sure you want to logout?
      </p>
      <div className="row">
        <div className="col-6">
          <NormalButton
            className="button-outline"
            onClick={handleClose}
            label="No"
          />
        </div>
        <div className="col-6">
          <NormalButton
            className="primaryButton"
            onClick={handleLogout}
            label="Yes"
          />
        </div>
      </div>
    </div>
  );
};

export default SignOutConfirmModal;
