import React from "react";
import Header from "component/Header";

export function WithoutFooter(props) {
  return (
    <>
      <Header />
      <div
        style={{
          paddingTop: 80,
        }}
        className="health-insurance-container"
      >
        {props.children}
      </div>
    </>
  );
}
