// import { HomeActionType } from "service/actionType"

const initialState = {
  userDetail: {},

};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "USER_DETAIL":
      return {
        ...state,
        userDetail: payload,
      };


    default:
      return state;
  }
};
