import { combineReducers } from "redux";
import common from "./common";
import healthInsurance from "./healthInsuranceReducer";
import bikeInsurance from "./bikeInsuranceReducer"
import carInsurance from "./carInsuranceReducer"

export const reducers = combineReducers({
  common,
  healthInsurance,
  bikeInsurance,
  carInsurance
});

