const initialState = {
  vehicleDetails: {},
  basicDetails: {},
  existingPolicyDetails: {},
  premiumCalculationPlan: {},
  selectedBikeBrand: null,
  isEditFromBikeBrand: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "BIKE_DETAILS":
      return {
        ...state,
        vehicleDetails: payload,
      };
    case "BASIC_DETAILS":
      return {
        ...state,
        basicDetails: payload,
      };

    case "EXISTING_POLICIY_DETAILS":
      return {
        ...state,
        existingPolicyDetails: payload,
      };
    case "PREMIUM_CALCULATION_REPONSE":
      return {
        ...state,
        premiumCalculationPlan: payload,
      };

    case "SELECTED_BIKE_BRAND_DETAILS":
      return {
        ...state,
        selectedBikeBrand: payload,
      };

    case "IS_EDIT_FROM_BIKE":
      return {
        ...state,
        isEditFromBikeBrand: payload,
      };
    case "CLEAR_ALL_BIKE_INSURANCE":
      return initialState;
    default:
      return state;
  }
};
