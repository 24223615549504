import React from "react";
import Footer from "component/Footer";
import Header from "component/Header";
import MobileStickeyTab from "component/Common/MobileStickeyTab";

export function MainLayout(props) {
  return (
    <>
      <Header />
      <div
        style={{
          paddingTop: 80,
        }}
        className="health-insurance-container"
      >
        {props.children}
      </div>
      <Footer />
    </>
  );
}
