import moment from "moment";

export function number_suffix(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export const calculate_age = (dob1) => {
  var today = new Date();
  var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  console.log(age_now);
  return age_now;
};

export const getRelationType = (memberVal) => {
  switch (memberVal) {
    case 1: {
      return "Self";
    }
    case 2: {
      return "Spouse";
    }
    case 3: {
      return "Son";
    }
    case 4: {
      return "Daughter";
    }
    case 5: {
      return "Mother";
    }
    case 6: {
      return "Father";
    }
    case 7: {
      return "Mother-in-law";
    }
    case 8: {
      return "Father-in-law";
    }

    default: {
      return "";
    }
  }
};

export const GetNumberToFormat = (value) => {
  const val = Math.abs(value);
  if (val >= 10000000) return `${(value / 10000000).toFixed(2)} Cr`;
  if (val >= 100000)
    return `${value % 100000 != 0
      ? (value / 100000).toFixed(2)
      : (value / 100000).toFixed(0)
      } Lakhs`;
  return value;
};

export const GetPremiumyearExpire = (year) => {
  var currentDate = moment(new Date());
  var futureYear = moment(currentDate).add(Number(year), "years");
  console.log(futureYear, "futureYear");
  return futureYear;
};

export function downloadPDF(url, filename) {
  const linkSource = `data:application/pdf;base64,${url}`;
  const downloadLink = document.createElement("a");
  const fileName = `${filename}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export function openNewTab(url) {
    // Open the URL in a new tab
    window.open(url, '_blank');
}

export const familyMembersList = [
  {
    title: "Self",
    isSelected: false,
    memberType: 1,
  },
  {
    title: "Spouse",
    isSelected: false,
    memberType: 2,
  },
  {
    title: "Son(s)",
    isSelected: false,
    count: 1,
    memberType: 3,
  },
  {
    title: "Daughter(s)",
    isSelected: false,
    count: 1,
    memberType: 4,
  },
  {
    title: "Mother",
    isSelected: false,
    memberType: 5,
  },
  {
    title: "Father",
    isSelected: false,
    memberType: 6,
  },
  {
    title: "Mother-in-law",
    isSelected: false,
    memberType: 7,
  },
  {
    title: "Father-in-law",
    isSelected: false,
    memberType: 8,
  },
];

export const illnessInitialList = {
  existingIllness: false,
  surgicalProcedure: false,
  none: true,
};


export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const GetInsuranceTypeForVehicle = (value) => {

  switch (value) {
    case 1: {
      return "Standalone"
    }
    case 2: {
      return "Comprehensive"
    }
    case 3: {
      return "Bundled"
    }
    case 4: {
      return "Third party"
    }
    default: {
      return ""
    }
  }

}