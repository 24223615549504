import React from "react";
import { Images } from "helpers/images";

import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className="row w-100">
        <div className="col-12 col-md-2 pb-3 pb-md-0">
          <img src={Images.logo} className={styles.logo} alt="logo" />
        </div>
        <div className="col-6 col-md-2 pt-3 pt-md-0">
          <p className="text-black_22 fw-700 fs-18 fs-md-16 m-0">PRODUCTS</p>
          <Link to="" className={`${styles.content} py-2`}>
            Car Insurance
          </Link>
          <Link to="" className={styles.content}>
            Bike Insurance
          </Link>
          <Link to="" className={`${styles.content} py-2`}>
            Health Insurance
          </Link>
          <Link to="" className={styles.content}>
            Shop Insurance
          </Link>
          <Link to="" className={`${styles.content} py-2`}>
            Property Insurance
          </Link>
        </div>
        <div className="col-6 col-md-2 pt-3 pt-md-0">
          <div>
            <p className="text-black_22 fw-700 fs-18 fs-md-16 m-0">RESOURCES</p>
            <Link to="" className={`${styles.content} py-2`}>
              Blog
            </Link>
            <Link to="" className={styles.content}>
              Press
            </Link>
            <Link to="" className={`${styles.content} py-2`}>
              Download Policy
            </Link>
            <Link to="" className={styles.content}>
              Contact E Mandate
            </Link>
            <Link to="" className={`${styles.content} py-2`}>
              Property Insurance
            </Link>
          </div>
        </div>
        <div className="col-6 col-md-3 pt-5 pt-md-0">
          <div>
            <p className="text-black_22 fw-700 fs-18 fs-md-16 m-0">
              AGENT & PARTNERSHIP
            </p>
            <Link to="" className={`${styles.content} py-2`}>
              Become a POS
            </Link>
            <Link to="" className={styles.content}>
              Blog
            </Link>
            <Link to="" className={`${styles.content} py-2`}>
              FAQ
            </Link>
          </div>
        </div>
      </div>
      <p className="fs-14 fs-md-12 pt-3 pt-md-5 text-gray-7E">
        This site is owned and operated by Rion Insura Broking Private Limited
        (CIN No - U67200TN2022PTC151253), an IRDA licensed Direct Broker (Life &
        General) bearing License No. 858, license Code IRDAI/DB 969/2022 license
        dated 17/10/2022 valid till 16/10/2025. The Prospect’s/visitor’s
        particulars could be shared with insurers. The information displayed on
        this website is of the insurers with whom Rion Insura Broking Private
        Limited has an agreement.
      </p>
      <hr className={styles.breakLine} />
      <div>
        <p className={styles.copyRights}>
          © 2022 rioninsura. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
