import React from "react";
import { Modal } from "react-bootstrap";

const CommonModal = (props) => {
  const {
    show,
    handleClose,
    title,
    children,
    centered = false,
    backdrop = "static",
  } = props;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered={centered}
      backdrop={backdrop}
    >
      {!!title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
export default CommonModal;

CommonModal.Declaration = (props) => {
  const { show, handleClose, title, children } = props;
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      {children}
    </Modal>
  );
};
