import React from "react";
import styles from "./styles.module.scss";

const InputBox = (props) => {
  let {
    placeholder = "",
    register = {},
    value = "",
    type = "text",
    name = "",
    maxlength,
    className = "",
    onKeyDown,
    disabled = false,
    errors,
    label = "",
    healthinsurance_form_input = false,
    onChange = () => {},
    accept,
  } = props;
  return (
    <div>
      {label !== "" && (
        <div>
          <label className={styles.label}>{label}</label>
        </div>
      )}

      <div>
        <input
          name={name}
          placeholder={placeholder}
          className={`${
            healthinsurance_form_input && styles.healthinsurance_form_input
          } ${className}`}
          maxLength={maxlength ? maxlength : null}
          {...register}
          defaultValue={value}
          type={type}
          autoComplete={"off"}
          onWheel={(event) => event.currentTarget.blur()}
          onKeyDown={onKeyDown}
          disabled={disabled}
          accept={accept}
          // onChange={onChange}
          // value={value}
        />
      </div>
      <div>
        {errors && (
          <span className="text-danger fs-12">
            {errors.message || errors.type}
          </span>
        )}
      </div>
    </div>
  );
};

export default InputBox;
