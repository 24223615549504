import React from "react";
import style from "./styles.module.scss";

const MobileStickeyTab = ({
  children,
  height = "0px",
  borderRadius = "0px",
}) => {
  const divStyle = {
    height,
    borderRadius: borderRadius,
  };
  return (
    <div>
      {" "}
      <div className={style.stickeyMobileTab} style={divStyle}>
        {children}
      </div>
    </div>
  );
};

export default MobileStickeyTab;
