const routers = [
  {
    path: "/",
    redirect: "/home/health-insurance",
  },
  {
    component: "HealthInsuranceSuccess",
    path: "/success/:page",
    componentPath: "pages/HealthInsurance/HealthInsuranceSuccess",
    name: "HealthInsuranceSuccess",
    auth: false,
    exact: true,
  },
  {
    component: "MainLayout",
    name: "Success",
    exact: false,
    path: "/payment",
    childrens: [
      {
        component: "SuccessPage",
        path: "/success",
        componentPath: "pages/SuccessPage",
        name: "SuccessPage",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/home",
    name: "Main",
    exact: false,
    childrens: [
      {
        component: "Home",
        path: "/:insurance",
        componentPath: "pages/Home",
        name: "Home",
        auth: false,
        exact: true,
      },

      {
        component: "HealthInsurance",
        path: "/health-insurance/:steps",
        componentPath: "pages/HealthInsurance",
        name: "HealthInsurance",
        auth: false,
        exact: true,
      },
      {
        component: "BikeInsurance",
        path: "/bike-insurance/:steps",
        componentPath: "pages/BikeInsurance",
        name: "BikeInsurance",
        auth: false,
        exact: true,
      },
      {
        component: "CarInsurance",
        path: "/car-insurance/:steps",
        componentPath: "pages/CarInsurance",
        name: "CarInsurance",
        auth: false,
        exact: true,
      },
    ],
  },

  //health insurance without footer
  {
    component: "WithoutFooter",
    path: "/health-insurance",
    name: "Main",
    exact: false,
    childrens: [
      {
        component: "ViewPlans",
        path: "/view-plans",
        componentPath: "pages/HealthInsurance/ViewPlans",
        name: "ViewPlans",
        auth: false,
        exact: true,
      },
      {
        component: "Quotation",
        path: "/quotation",
        componentPath: "pages/HealthInsurance/Quotation",
        name: "Quotation",
        auth: false,
        exact: true,
      },
      {
        component: "Nominee",
        path: "/nominee-details/:param",
        componentPath: "pages/HealthInsurance/Nominee",
        name: "Nominee",
        auth: false,
        exact: true,
      },
      {
        component: "NomineePreview",
        path: "/nominee-preview",
        componentPath: "pages/HealthInsurance/NomineePreview",
        name: "NomineePreview",
        auth: false,
        exact: true,
      },
      // {
      //   component: "HealthInsuranceSuccess",
      //   path: "/success",
      //   componentPath: "pages/HealthInsurance/HealthInsuranceSuccess",
      //   name: "HealthInsuranceSuccess",
      //   auth: false,
      //   exact: true,
      // },
    ],
  },

  //Bike insurance

  {
    component: "WithoutFooter",
    path: "/bike-insurance",
    name: "Main",
    exact: false,
    childrens: [
      {
        component: "ViewPlans",
        path: "/view-plans",
        componentPath: "pages/BikeInsurance/ViewPlans",
        name: "ViewPlans",
        auth: false,
        exact: true,
      },
      {
        component: "Quotation",
        path: "/quotation",
        componentPath: "pages/BikeInsurance/Quotation",
        name: "Quotation",
        auth: false,
        exact: true,
      },
      {
        component: "BikeOwnerDetail",
        path: "/bike-details/:steps",
        componentPath: "pages/BikeInsurance/BikeOwnerDetail",
        name: "BikeOwnerDetail",
        auth: false,
        exact: true,
      },
    ],
  },

  //Car insurance

  {
    component: "WithoutFooter",
    path: "/car-insurance",
    name: "Main",
    exact: false,
    childrens: [
      {
        component: "ViewPlans",
        path: "/view-plans",
        componentPath: "pages/CarInsurance/ViewPlans",
        name: "ViewPlans",
        auth: false,
        exact: true,
      },
      {
        component: "Quotation",
        path: "/quotation",
        componentPath: "pages/CarInsurance/Quotation",
        name: "Quotation",
        auth: false,
        exact: true,
      },
      {
        component: "CarOwnerDetail",
        path: "/car-details/:steps",
        componentPath: "pages/CarInsurance/CarOwnerDetail",
        name: "CarOwnerDetail",
        auth: false,
        exact: true,
      },
    ],
  },
];
export default routers;
