// import { HomeActionType } from "service/actionType"

import { familyMembersList, illnessInitialList } from "helpers/common";

const initialState = {
  healthInsuranceDetail: {},
  healthInsuranceDetailApi: {},
  healthInsuranceNomineeDetails: {},
  saveProposelResponse: {},
  premiumCalculation: {},
  healthInfoQue: [],
  healthAdditionalQue: [],
  healthAdditionalQueTwo: [],
  healthMemberDetails: [],
  isSelfNominee: false,
  selectedFamilyMembers: familyMembersList,
  illnessList: illnessInitialList,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "USER_STEPS":
      return {
        ...state,
        healthInsuranceDetail: payload,
      };
    case "USER_STEPS_API":
      return {
        ...state,
        healthInsuranceDetailApi: payload,
      };
    case "NOMINEE_DETAILS":
      return {
        ...state,
        healthInsuranceNomineeDetails: payload,
      };
    case "SAVE_PROPOSEL_RESPONSE":
      return {
        ...state,
        saveProposelResponse: payload,
      };
    case "SAVE_PREMIUM_CALCULATION":
      return {
        ...state,
        premiumCalculation: payload,
      };
    case "SAVE_HEALTH_QUESTION":
      return {
        ...state,
        healthInfoQue: payload,
      };
    case "SAVE_HEALTH_ADDITIONAL_QUESTION":
      return {
        ...state,
        healthAdditionalQue: payload,
      };
    case "SAVE_HEALTH_ADDITIONAL_QUESTION_TWO":
      return {
        ...state,
        healthAdditionalQueTwo: payload,
      };
    case "SAVE_SELF_NOMINEE":
      return {
        ...state,
        isSelfNominee: payload,
      };
    case "SAVE_HEALTH_MEMBER_DETAILS":
      return {
        ...state,
        healthMemberDetails: payload,
      };

    case "SELECTED_FAMILY_MEMBERS":
      return {
        ...state,
        selectedFamilyMembers: payload,
      };

    case "SET_PRE_ILLNESS":
      return {
        ...state,
        illnessList: payload,
      };

    case "CLEAR_HEALTH_INSURANCE_STEPS":
      return {
        ...state,
        healthInsuranceDetailApi: {},
        selectedFamilyMembers: familyMembersList,
        illnessList: illnessInitialList,
      };
    case "CLEAR_ALL_DETAILS":
      return initialState;

    default:
      return state;
  }
};
