export const emailValidation = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  message: "Invalid email address",
};

export const passwordValidation = {
  value:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?& ]{8,}$/s,
  message:
    "Password must contain an uppercase and lowercase letter, a digit and a special character",
};

export const passwordMinimumtext = {
  value: 8,
  message: "Password should contain minimum 8 letters",
};

export const passwordMaximumtext = {
  value: 16,
  message: "Password should not contain more than 16 letters",
};

export const mobileNumbervalidation = {
  value: /^[0-9\b]+$/,
  message: "Mobile number should contain only number",
};

export const mobileMinvalidation = {
  value: 10,
  message: "Mobile number should contain 10 number",
};

export const onlyTextValidation = {
  value: /^[a-zA-Z ]*$/,
  message: "This field should contain only alphabets",
};

export const alphaNumericWithoutSpaceAndSplcharValidation = {
  value: /^[a-zA-Z0-9]*$/,
  message: "Field should not contain space and special characters",
};

export const pincodeValidation = {
  value: /^[0-9]*$/,
  message: "Pincode should contain only numbers",
};

export const pincodeMaxMinValidation = {
  value: 6,
  message: "Pincode should contain only 6 digits",
};

export const onlyNumbervalidation = {
  value: /^[0-9\b]+$/,
  message: "This field should contain only numbers",
};
