import React, { useEffect, useState } from "react";

const ResendOTP = (props) => {
  const {
    onClick,
  } = props;
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  const handleResend = () => {
    setTimer(120); 
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="d-flex justify-content-between pt-3">
    <p
      className={`text-blue-BC text-decoration-underline fs-14 fs-md-12 cursor-pointer ${timer > 0 ? 'disabled' : ''}`}
      onClick={timer === 0 ? handleResend : undefined}
    >
      {timer > 0 ? `Resend OTP in ${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}` : 'Didn’t receive OTP? Send Again'}
    </p>
  </div>
  );
};

export default ResendOTP;
