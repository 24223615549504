const Endpoints = {
  //Auth
  login: "/user/otp/send",
  resendOTP: "/user/otp/resend",
  verifyOTP: "/user/otp/verify",

  //health insurance
  healthBasicDetails: "/integrators/health/premiumCalculation",
  saveProposal: "/integrators/health/quoteDetails",
  getAllPlanshealthInsurance: "/integrators/health/premiumCalculation/all",
  healthInsurancePaymentUrl: "/integrators/health/quotePayment",
  healthInsuranceSuccessUrl: "/integrators/health/onSuccesPayment",
  healthInsuranceSuccessPayment: "/integrators/health/quoteDetails",

  //vehicel master api
  masterVehicelApi: "/masters/vehicles",

  //car insurance
  carInsurancePremiumCalculation: "/integrators/FW/premiumCalculations",
  carInsurancePaymentUrl: "/integrators/FW/quotePayment",
  carInsuranceQuoteDetails: "/integrators/FW/quoteDetails",
  carInsuranceDownloadInvoice: "/integrators/FW/policyDownload",


  //bike insurance
  bikeInsuranceRtoMasterApi: "/masters/rto",
  bikeInsuranceQuoteDetails: "/integrators/TW/quoteDetails",
  bikeInsurancePremiumCalculation: "/integrators/TW/premiumCalculations",
  bikeInsurancePaymentUrl: "/integrators/TW/quotePayment",
  bikeInsuranceInsurerList: "/masters/insurers",
  bikeInsuranceDownloadInvoice: "/integrators/TW/policyDownload",
  insuranceSendAndVerifyOtp: "/user/otp",

  //policy
  companyPolicy: "/masters/policy"
};


const APIMethods = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
};

export default {
  Endpoints,
  APIMethods,
};
