const importImage = (fileName) => {
  return require(`assets/images/${fileName}`);
};

const importIcons = (fileName) => {
  return require(`assets/icons/${fileName}`);
};

export const Images = {
  logo: importImage("logo.svg"),
  homePos: importImage("home-pos.png"),
  partner1: importImage("partner1.png"),
  partner2: importImage("partner2.png"),
  partner_1: importImage("partner-1.png"),
  partner_2: importImage("partner-2.png"),
  partner_3: importImage("partner-3.png"),
  favorite1: importImage("favorite1.svg"),
  favorite2: importImage("favorite2.svg"),
  favorite3: importImage("favorite3.svg"),
  favorite4: importImage("favorite4.svg"),
  viewPlans: importImage("view-plans.png"),
  propose_step: importImage("proposer_step_icon.svg"),
  members_step: importImage("members_step_icon.svg"),
  medical_step: importImage("medical_step_icon.svg"),
  nominee_step: importImage("nominee_step_icon.svg"),
  getInsurance: importImage("get-insurance.svg"),
  health_1: importImage("health-1.png"),
  health_2: importImage("health-2.png"),
  health_3: importImage("health-3.png"),
  health_step_1: importImage("health_step1.svg"),
  health_step_2: importImage("health_step2.svg"),
  health_step_3: importImage("health_step3.svg"),
  health_step_4: importImage("health_step4.svg"),
  care_logo: importImage("care_logo.svg"),
  chola_logo: importImage("chola-logo.svg"),
  bike_insurance_home: importImage("bike_insurance_home_img.svg"),
  brand_logo: importImage("brand-one.png"),
  hyundai_logo: importImage("hyundai-logo.png"),
  tvs_logo: importImage("tvs-logo.png"),
  hero_logo: importImage("hero-logo.png"),
  cholaLogo: importImage("chola-logo.png"),
  bike_logo: importImage("bike.png"),
  car_logo: importImage("summaryPage_car.png"),


  success_img: importImage("health-success.png"),
  nexon: importImage("nexon.png"),
  swift: importImage("swift.png"),
};

export const Icons = {
  tick: importIcons("tick.svg"),
  star: importIcons("star.svg"),
  medical: importIcons("medical.svg"),
  commercial: importIcons("commercial.svg"),
  bike: importIcons("bike.svg"),
  car: importIcons("car.svg"),
  priceTag: importIcons("price-tag.png"),
  flash: importIcons("flash.png"),
  simpleClaims: importIcons("simple-claims.svg"),
  lovedCustomer: importIcons("loved-customer.svg"),
  advice: importIcons("advice.svg"),
  lowPremium: importIcons("low-premium.svg"),
  plus: importIcons("plus.svg"),
  minus: importIcons("minus.svg"),
  filterTick: importIcons("tick-filter.svg"),
  guard: importIcons("guard.svg"),
  flash_plans: importIcons("flash-plans.svg"),
  workspace_premium: importIcons("workspace_premium.svg"),
  view_more: importIcons("view_more_arrow.svg"),
  view_less: importIcons("view_less_arrow.svg"),
  search_Icon: importIcons("search-icon.svg"),
  sideBar: importIcons("sidebar-icon.svg"),
  proposer_detail_icon: importIcons("proposer_detail_icon.svg"),
  members_detail_icon: importIcons("members_detail_icon.svg"),
  medical_detail_icon: importIcons("medical_detail_icon.svg"),
  nominee_detail_icon: importIcons("nominee_detail_icon.svg"),
  editIcon: importIcons("edit.svg"),
  filter: importIcons("filter.svg"),
  closeIcon: importIcons("x-circle.svg"),
  closeWithoutCircle: importIcons("close-icon.svg"),
  or_icon: importIcons("or_icon.svg"),
  warningIcon: importIcons("warning.svg"),
  percentage: importIcons("percentage.svg"),
  calendar: importIcons("calendar.svg"),
  stepperBackArrow: importIcons("stepper-back-arrow.svg"),
  mobileAccordionIconUp: importIcons("mobile_accordian_icon.svg"),
  mobileAccordionIconDown: importIcons("mobile_accordian_icon_down.svg"),
  hdfc_logo: importIcons("hdfc-logo.png"),
};
