//To handle more microservice

export const getbaseUrl = (url) => {
    switch (url) {

        case "auth":
            return process.env.REACT_APP_API_AUTH_URL;
        // case "health":
        //     return process.env.REACT_APP_API_AUTH_URL;
        default:
            return process.env.REACT_APP_API_AUTH_URL;
    }
};
