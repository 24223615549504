import React, { useState } from "react";
import NormalButton from "component/Common/Button";
import InputBox from "component/Common/InputBox";
import {
  mobileMinvalidation,
  mobileNumbervalidation,
} from "helpers/FormValidation";
import { Icons } from "helpers/images";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import styles from "./styles.module.scss";
import Timer from "component/Common/Timer";
import { request } from "service";
import endpoints from "service/endpoints";
import { Toast } from "service/toast";
import { saveUserToken } from "service/utilities";
import { useDispatch, useSelector } from "react-redux";
import ResendOTP from "component/Common/ResendOTP";

const SignInModal = (props) => {
  const { handleClose, openModal } = props;

  const dispatch = useDispatch();
  const [loginSteps, setLoginSteps] = useState(1);
  const [otp, setOtp] = useState("");

  const {
    watch,
    register,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const onSubmit = async (inputs) => {
    let body = {
      url: endpoints.Endpoints.login,
      method: endpoints.APIMethods.POST,
      data: { country_code: "+91", mobile_no: inputs?.number, user_role: 4 },
      baseurl: "auth",
    };
    let { data = {}, status } = await request(body);
    if (status == 200) {
      Toast({ type: "success", message: data?.message });
      setLoginSteps(2);
    }
  };

  const resendOTP = async (inputs) => {
    let body = {
      url: endpoints.Endpoints.resendOTP,
      method: endpoints.APIMethods.POST,
      data: { country_code: "+91", mobile_no: inputs?.number, user_role: 4 },
      baseurl: "auth",
    };
    let { data = {}, status } = await request(body);
    if (status == 200) {
      Toast({ type: "success", message: data?.message });
      setLoginSteps(2);
    }
  };

  const handleVerifyOTP = async () => {
    let body = {
      url: endpoints.Endpoints.verifyOTP,
      method: endpoints.APIMethods.POST,
      data: {
        country_code: "+91",
        mobile_no: getValues("number"),
        code: otp,
        user_role: 4,
      },
      baseurl: "auth",
    };
    let { data = {}, status } = await request(body);
    if (status == 200) {
      dispatch({
        type: "USER_DETAIL",
        payload: { mobile: data?.data?.mobile },
      });
      saveUserToken(data?.data?.token);
      Toast({ type: "success", message: data?.message });
      handleClose();
      return;
    }
    openModal();
  };
  return (
    <div className={styles.container}>
      <div className="text-end">
        <img
          src={Icons.closeIcon}
          className="cursor-pointer"
          alt="close"
          onClick={handleClose}
        />
      </div>
      {loginSteps === 1 ? (
        <>
          <div>
            <p className="text-black fs-32 fs-md-24 fw-700">Let’s Sign In</p>
            <p className="text-gray-7 fs-16 fs-md-14">
              Sign in using your 10 digit mobile number
            </p>
          </div>

          <div className="pt-2">
            <InputBox
              healthinsurance_form_input
              label="Phone number"
              errors={errors?.number}
              register={register("number", {
                required: "*number is required",
                pattern: mobileNumbervalidation,
                maxLength: mobileMinvalidation,
                minLength: mobileMinvalidation,
              })}
            />

            <NormalButton
              className={`${
                !!watch("number") ? "primaryButton" : "buttonDisabled"
              } my-4 my-md-5`}
              label="Continue"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </>
      ) : (
        <>
          <p className="text-black-3E fs-32 fs-md-24 fw-700">Enter OTP</p>
          <p className="text-gray-7 fs-20 fs-md-16 ">
            We’ve sent OTP to{" "}
            <span className="text-black-3E">{getValues("number")}</span>
          </p>

          <p
            className="text-blue-BC text-decoration-underline fs-14 cursor-pointer"
            onClick={() => setLoginSteps(1)}
          >
            Edit Mobile Number
          </p>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            containerStyle={styles.OTPContainer}
            inputStyle={styles.inputStyle}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
          />

          <ResendOTP onClick={handleSubmit(resendOTP)}/>

          <NormalButton
            className="primaryButton"
            label="Verify & Login"
            onClick={handleVerifyOTP}
          />
        </>
      )}
    </div>
  );
};

export default SignInModal;
