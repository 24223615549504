import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Routes from "./routes/index";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import "./themes/scss/index.scss";
// import "font-awesome/css/font-awesome.min.css";
import "react-notifications/lib/notifications.css";

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import dotenv from "dotenv";
dotenv.config();
ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
