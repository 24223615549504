import React, { useState } from "react";
import NormalButton from "component/Common/Button";
import CommonModal from "component/Common/CommonModal";
import SignOutConfirmModal from "component/Common/SignOutConfirmModal";
import SignInModal from "component/SignInModal";
import { Icons, Images } from "helpers/images";
import { NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearAllData } from "redux/actions/healthInsuranceAction";
import styles from "./styles.module.scss";

const Header = () => {
  const [signInPopUp, setSignInPopUp] = useState(false);
  const [signOutPopup, setSignOutPopup] = useState(false);

  const dispatch = useDispatch();

  useSelector((state) => console.log(state?.healthInsurance, "data"));

  const handleClose = () => setSignInPopUp(!signInPopUp);
  const handleCloseSignOut = () => setSignOutPopup(!signOutPopup);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearAllData());
    handleCloseSignOut();
  };

  const token = localStorage.getItem("token");
  return (
    <div>
      <Navbar fixed="top" expand="md" className={styles.container}>
        <Navbar.Toggle
          className={styles.toggle}
          aria-controls="offcanvasNavbar-expand-md"
        >
          <img src={Icons.sideBar} alt="sidebar" />
        </Navbar.Toggle>
        <Navbar.Brand className="mx-auto" href="/">
          <img src={Images.logo} alt="logo" />
        </Navbar.Brand>

        <Navbar.Offcanvas id="offcanvasNavbar-expand-md" placement="start">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbar-expand-md">
              <img src={Images.logo} alt="logo" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row w-100 align-items-center mx-auto">
              {/* <div className=" col-12 col-md-5 d-block d-md-flex align-items-baseline">
                <NavDropdown
                  title="Products"
                  className={`${styles.navDropdown} ps-0 ps-md-5`}
                >
                  <NavDropdown.Item eventKey="1">Product 1</NavDropdown.Item>
                </NavDropdown>
                <Link to="" className={`${styles.space} text-black-22 `}>
                  Renewal
                </Link>
                <NavDropdown title="Claims" className={styles.navDropdown}>
                  <NavDropdown.Item eventKey="1">Claim 1</NavDropdown.Item>
                </NavDropdown>
                <Link to="" className={`${styles.space} text-black-22 `}>
                  Support
                </Link>
              </div> */}
              <div className="offset-0 offset-md-8 col-12 col-md-2 text-md-end">
                <NormalButton
                  className="transparentButton"
                  label={!!token ? "Logout" : "Signup/Login"}
                  onClick={!!token ? handleCloseSignOut : handleClose}
                />
              </div>
              <div className="col-12 col-md-2 pt-3 pt-md-0">
                <NormalButton
                  className="primaryButton"
                  label="Become an Agent"
                />
              </div>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>

      <CommonModal show={signInPopUp} handleClose={handleClose} centered={true}>
        <SignInModal
          handleClose={handleClose}
          openModal={() => setSignInPopUp(true)}
        />
      </CommonModal>

      <CommonModal
        show={signOutPopup}
        handleClose={handleCloseSignOut}
        centered={true}
      >
        <SignOutConfirmModal
          handleClose={handleCloseSignOut}
          handleLogout={handleLogout}
        />
      </CommonModal>
    </div>
  );
};

export default Header;
