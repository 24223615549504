const initialState = {
  vehicleDetails: {},
  basicDetails: {},
  existingPolicyDetails: {},
  premiumCalculationPlan: {},
  selectedCarBrand: null,
  isEditFromCarBrand: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "CAR_OWNER_BASIC_DETAILS":
      return {
        ...state,
        basicDetails: payload,
      };
    case "CAR_BASIC_DETAILS":
      return {
        ...state,
        vehicleDetails: payload,
      };
    case "CAR_INSURANCE_EXISTING_DETAILS":
      return {
        ...state,
        existingPolicyDetails: payload,
      };
    case "CAR_PREMIUM_PLAN":
      return {
        ...state,
        premiumCalculationPlan: payload,
      };

    case "SELECTED_CAR_BRAND_DETAILS":
      return {
        ...state,
        selectedCarBrand: payload,
      };

    case "IS_EDIT_FROM_CAR":
      return {
        ...state,
        isEditFromCarBrand: payload,
      };
    case "CLEAR_ALL_CAR_INSURANCE":
      return initialState;
    default:
      return state;
  }
};
