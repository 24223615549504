export const healthInsuranceSteps = (data) => {
  return {
    type: "USER_STEPS",
    payload: data,
  };
};

export const healthInsuranceStepsAPi = (data) => {
  return {
    type: "USER_STEPS_API",
    payload: data,
  };
};

export const healthInsuranceNomineeDetails = (data) => {
  return {
    type: "NOMINEE_DETAILS",
    payload: data,
  };
};

export const SaveProposalResponsestore = (data) => {
  return {
    type: "SAVE_PROPOSEL_RESPONSE",
    payload: data,
  };
};

export const IsSelfNominee = (data) => {
  return {
    type: "SAVE_SELF_NOMINEE",
    payload: data,
  };
};

export const SavePremiumCalculation = (data) => {
  return {
    type: "SAVE_PREMIUM_CALCULATION",
    payload: data,
  };
};

export const SaveHealthQuestions = (data) => {
  return {
    type: "SAVE_HEALTH_QUESTION",
    payload: data,
  };
};

export const SaveHealthMemberDetails = (data) => {
  return {
    type: "SAVE_HEALTH_MEMBER_DETAILS",
    payload: data,
  };
};

export const SaveHealthAdditionalQuestions = (data) => {
  return {
    type: "SAVE_HEALTH_ADDITIONAL_QUESTION",
    payload: data,
  };
};

export const SaveHealthAdditionalQuestionsTwo = (data) => {
  return {
    type: "SAVE_HEALTH_ADDITIONAL_QUESTION_TWO",
    payload: data,
  };
};

export const selectedFamilyMembers = (data) => {
  return {
    type: "SELECTED_FAMILY_MEMBERS",
    payload: data,
  };
};

export const setPreIllnessList = (data) => {
  return {
    type: "SET_PRE_ILLNESS",
    payload: data,
  };
};

export const clearHealthInsuranceSteps = () => {
  return {
    type: "CLEAR_HEALTH_INSURANCE_STEPS",
  };
};

export const clearAllData = () => {
  return {
    type: "CLEAR_ALL_DETAILS",
  };
};
